import { V1ReleaseUpgradeInfo } from '@endorlabs/api_client';

import { DetailDrawerSection } from '../../../../components/DetailDrawer';
import { PotentialConflictsSection } from '../PotentialConflictsSection';

export const RemediationDetailDrawerPotentialConflicts = ({
  upgradeInfo,
}: {
  upgradeInfo?: V1ReleaseUpgradeInfo;
}) => {
  return (
    <DetailDrawerSection>
      <PotentialConflictsSection upgradeInfo={upgradeInfo} />
    </DetailDrawerSection>
  );
};
