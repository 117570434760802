import { Box, Paper, Stack, Typography, useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { CommitCommitType } from '@endorlabs/api_client';
import {
  AssuredPackageVersionResource,
  parseSecurityAttestationPatch,
} from '@endorlabs/endor-core/AssuredPackageVersion';
import {
  DateDisplay,
  DiffStat,
  ExpandCollapseControl,
  IconEndorLogo,
  IconGitCommit,
  RowStack,
} from '@endorlabs/ui-common';

import { Patch, PatchDisplayAccordion } from './PatchDisplayAccordion';

export const AssuredPackageVersionPatchDetail = ({
  assuredPackageVersion,
}: {
  assuredPackageVersion?: AssuredPackageVersionResource;
}) => {
  const { space } = useTheme();

  const [expandAll, setExpandAll] = useState(false);

  const { added, removed, patches } = useMemo(() => {
    const added = assuredPackageVersion?.spec.upgrade_summary?.line_added ?? 0;
    const removed =
      assuredPackageVersion?.spec.upgrade_summary?.line_added ?? 0;

    const patches = (
      assuredPackageVersion?.spec.security_attestation?.patches ?? []
    ).flatMap((p): Patch[] => {
      const commit = p.included_commits?.[0];
      const commitDate = commit?.commit_date;
      const sha = commit?.sha ? commit?.sha.slice(0, 7) : undefined;
      const isEndorPatch = p.included_commits?.some(
        (c) => c.commit_type === CommitCommitType.Manual
      );

      const diffStats = parseSecurityAttestationPatch(p);
      return diffStats.map((stat) => ({
        ...stat,
        commitDate,
        isEndorPatch,
        sha,
      }));
    });

    return { added, removed, patches };
  }, [assuredPackageVersion]);

  return (
    <Box paddingX={space.sm}>
      <RowStack justifyContent="space-between">
        <ExpandCollapseControl onClick={setExpandAll} />

        <DiffStat added={added} removed={removed} />
      </RowStack>

      <Stack gap={space.md} marginTop={4}>
        {patches.map((p, index) => (
          <Stack gap={space.sm} key={index}>
            <RowStack>
              {p.isEndorPatch ? (
                <IconEndorLogo sx={{ color: 'text.secondary' }} />
              ) : (
                <IconGitCommit sx={{ color: 'text.secondary' }} />
              )}

              <Typography component="span" variant="body2">
                <FM
                  defaultMessage="<b>{patchType}</b> on {date}"
                  values={{
                    patchType: p.isEndorPatch ? (
                      <FM defaultMessage="Endor Patch" />
                    ) : (
                      <FM defaultMessage="Original Patch" />
                    ),
                    date: <DateDisplay value={p.commitDate} />,
                    b: (value) => <b>{value}</b>,
                  }}
                />
              </Typography>
            </RowStack>

            {p && (
              <Paper sx={{ marginLeft: space.md }} variant="outlined">
                <PatchDisplayAccordion expanded={expandAll} patch={p} />
              </Paper>
            )}
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};
