import { FormattedMessage as FM } from 'react-intl';

import { RemediationRecommendationType } from '../constants';
import { RemediationRecommendationTypeMessages } from '../locales';

export interface RemediationRecommendationTypeLabelProps {
  value?: RemediationRecommendationType;
}

export const RemediationRecommendationTypeLabel = ({
  value = RemediationRecommendationType.Upgrade,
}: RemediationRecommendationTypeLabelProps) => {
  const message = RemediationRecommendationTypeMessages[value];
  return message ? <FM {...message} /> : value;
};
