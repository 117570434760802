import { Stack, useTheme } from '@mui/material';

import { IconArrowRight, Pip, TShirtSize } from '@endorlabs/ui-common';

export type VersionUpgradeDisplayProps = {
  fromVersion?: string;
  toVersion?: string;
  size?: TShirtSize;
};

export const VersionUpgradeDisplay = ({
  fromVersion,
  toVersion,
  size = 'small',
}: VersionUpgradeDisplayProps) => {
  const { spacing } = useTheme();
  return (
    <Stack alignItems="center" direction="row" flexWrap="wrap" gap={spacing(1)}>
      <Pip color="warning" label={fromVersion} size={size} variant="outlined" />
      <IconArrowRight fontSize={size} />
      <Pip color="success" label={toVersion} size={size} variant="outlined" />
    </Stack>
  );
};
