import { Stack, Typography } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { V1ReleaseUpgradeInfo } from '@endorlabs/api_client';
import {
  AttributeDisplayRecord,
  AttributeDisplayStack,
  PackageVersionNameDisplay,
  ProjectNameDisplay,
} from '@endorlabs/ui-common';

const RemediationDetailsRecords: AttributeDisplayRecord[] = [
  {
    attributeKey: 'projectName',
    heading: <FM defaultMessage="Project" />,
    variant: 'column',
  },
  {
    attributeKey: 'packageName',
    heading: <FM defaultMessage="Package" />,
    variant: 'column',
  },
];

export const RemediationDetails = ({
  upgradeInfo,
}: {
  upgradeInfo?: V1ReleaseUpgradeInfo;
}) => {
  const manifestFiles = upgradeInfo?.direct_dependency_manifest_files ?? [];

  return (
    <AttributeDisplayStack
      attributeRecords={RemediationDetailsRecords}
      headingWidth="16ch"
      resource={{
        projectName: <ProjectNameDisplay name={upgradeInfo?.project} />,
        packageName: (
          <Stack direction="column">
            <PackageVersionNameDisplay
              packageVersion={{
                meta: { name: upgradeInfo?.root_package_version ?? '' },
              }}
            />

            {manifestFiles.map((value, index) => (
              <Typography
                color="text.secondary"
                component="span"
                key={index}
                sx={{ wordBreak: 'break-all' }}
                variant="body2"
              >
                {value}
              </Typography>
            ))}
          </Stack>
        ),
      }}
      variant="column"
    />
  );
};
