import { Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { remove as _remove } from 'lodash-es';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  ButtonLinkSecondary,
  ButtonStack,
  PackageVersionNameDisplay,
} from '@endorlabs/ui-common';

import { getRemediationsPath } from '../../../../../src/routes';
import { DetailDrawerContainer } from '../../../../components/DetailDrawer';
import { InfoDrawerContentProps } from '../../../../components/InfoDrawer';
import { AssuredPackageVersionPatchesSection } from '../../../AssuredPackageVersion';
import {
  RemediationRecommendationType,
  RemediationRecommendationTypeLabel,
  useRemediationDetailData,
  VersionUpgradeDisplay,
} from '../..';
import { RemediationDetailDrawerBreakingChanges } from './RemediationDetailDrawerBreakingChanges';
import { RemediationDetailDrawerOverview } from './RemediationDetailDrawerOverview';
import { RemediationDetailDrawerPotentialConflicts } from './RemediationDetailDrawerPotentialConflicts';

const RemediationDetailDrawerTabRecords = [
  {
    label: <FM defaultMessage="Overview" />,
    value: 'overview',
  },
  {
    label: <FM defaultMessage="Patches" />,
    value: 'patches',
  },
  {
    label: <FM defaultMessage="Potential Conflicts" />,
    value: 'conflicts',
  },
  {
    label: <FM defaultMessage="Breaking Changes" />,
    value: 'breakingChanges',
  },
];

export interface RemediationDetailDrawerProps extends InfoDrawerContentProps {
  namespace: string;
  upgradeUuid: string;
}

/**
 * Detail Info Drawer for a Remediations Table rows
 */
export const RemediationDetailDrawer = ({
  namespace,
  upgradeUuid,
}: RemediationDetailDrawerProps) => {
  const { space } = useTheme();

  const {
    isLoading,
    isMagicPatchUpgrade,
    versionUpgrade,
    assuredPackageVersion,
  } = useRemediationDetailData({ namespace, versionUpgradeUuid: upgradeUuid });

  const tabRecords = useMemo(() => {
    const tabRecords = RemediationDetailDrawerTabRecords.slice();
    if (!isMagicPatchUpgrade) {
      _remove(tabRecords, (t) => t.value === 'patches');
    }

    return tabRecords;
  }, [isMagicPatchUpgrade]);

  if (!namespace) return null;

  const upgradeInfo = versionUpgrade?.spec?.upgrade_info;
  const dependencyName = upgradeInfo?.direct_dependency_package ?? '';

  const title = (
    <Stack direction="column" spacing={2} alignItems="start">
      {isLoading ? (
        <>
          <Skeleton width={240} />
          <Skeleton width={160} />
        </>
      ) : (
        <>
          <PackageVersionNameDisplay
            packageVersion={{ meta: { name: dependencyName } }}
            size="large"
          />

          <Stack alignItems="center" direction="row" spacing={space.xs}>
            <Typography variant="body1">
              <RemediationRecommendationTypeLabel
                value={
                  isMagicPatchUpgrade
                    ? RemediationRecommendationType.Patch
                    : RemediationRecommendationType.Upgrade
                }
              />
            </Typography>

            <VersionUpgradeDisplay
              fromVersion={upgradeInfo?.from_version}
              toVersion={upgradeInfo?.to_version}
            />
          </Stack>
        </>
      )}

      <ButtonStack>
        {/*
        TODO(UI-1508): enable patch instruction action when exists
        {isMagicPatchUpgrade && (
          <ButtonPrimary>
            <FM defaultMessage="Patch Instructions" />
          </ButtonPrimary>
        )}
        */}

        {versionUpgrade && (
          <ButtonLinkSecondary
            linkProps={{
              to: getRemediationsPath({
                tenantName: versionUpgrade.tenant_meta.namespace,
                uuid: versionUpgrade?.uuid,
              }),
            }}
          >
            <FM defaultMessage="View Details" />
          </ButtonLinkSecondary>
        )}
      </ButtonStack>
    </Stack>
  );

  const overviewContent = (
    <RemediationDetailDrawerOverview
      assuredPackageVersion={assuredPackageVersion}
      isLoading={isLoading}
      versionUpgrade={versionUpgrade}
    />
  );

  const patchesContent = (
    <AssuredPackageVersionPatchesSection
      assuredPackageVersion={assuredPackageVersion}
    />
  );

  const potentialConflictsContent = (
    <RemediationDetailDrawerPotentialConflicts upgradeInfo={upgradeInfo} />
  );

  const breakingChangesContent = (
    <RemediationDetailDrawerBreakingChanges upgradeInfo={upgradeInfo} />
  );

  return (
    <DetailDrawerContainer
      headerProps={{
        title,
      }}
      tabProps={{
        disablePadding: true,
        id: 'remediation-detail-drawer',
        tabPanelMap: {
          breakingChanges: breakingChangesContent,
          conflicts: potentialConflictsContent,
          patches: patchesContent,
          overview: overviewContent,
        },
        tabRecords: tabRecords,
      }}
    ></DetailDrawerContainer>
  );
};
