export type JSONParseResult<T = unknown> =
  | { ok: true; value: T }
  | { ok: false; error: Error };

/**
 * Wrapper around JSON.parse to parse without throwing exception
 */
export const safeParse = <T>(
  ...args: Parameters<typeof JSON.parse>
): JSONParseResult<T> => {
  try {
    const value = JSON.parse(...args);
    return { ok: true, value };
  } catch (error) {
    return { ok: false, error: error as Error };
  }
};
