import { Stack, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { AssuredPackageVersionResource } from '@endorlabs/endor-core/AssuredPackageVersion';
import {
  IconCode,
  IconDownload,
  IconFileText,
  IconRocket,
  IconTitleDisplay,
  LinkSecondary,
} from '@endorlabs/ui-common';

export const AssuredPackageVersionPatchAttestations = ({
  assuredPackageVersion,
}: {
  assuredPackageVersion?: AssuredPackageVersionResource;
}) => {
  const { space } = useTheme();

  const patchAttestations = useMemo(() => {
    // TODO: remove entry or change label when attestation url does not exist
    return [
      {
        key: 'build',
        Icon: IconCode,
        label: <FM defaultMessage="Build Logs" />,
        link: assuredPackageVersion?.spec.build_attestation?.logs_url,
      },
      {
        key: 'test',
        Icon: IconFileText,
        label: <FM defaultMessage="Test Logs" />,
        link: assuredPackageVersion?.spec.test_attestation?.logs_url,
      },
      {
        key: 'deploy',
        Icon: IconRocket,
        label: <FM defaultMessage="Deploy Logs" />,
        link: assuredPackageVersion?.spec.deploy_attestation?.logs_url,
      },
      {
        key: 'reproducible',
        Icon: IconDownload,
        label: <FM defaultMessage="Reproducible Build" />,
        link: assuredPackageVersion?.spec.reproducible_build_source_code_url,
      },
    ];
  }, [
    assuredPackageVersion?.spec.build_attestation?.logs_url,
    assuredPackageVersion?.spec.deploy_attestation?.logs_url,
    assuredPackageVersion?.spec.reproducible_build_source_code_url,
    assuredPackageVersion?.spec.test_attestation?.logs_url,
  ]);

  return (
    <Stack gap={space.xs}>
      {patchAttestations.map(({ key, Icon, label, link }) => (
        <IconTitleDisplay
          key={key}
          iconFrame
          Icon={Icon}
          IconProps={{ sx: { color: 'text.secondary' } }}
          title={
            link ? (
              <LinkSecondary to={link} target="_blank">
                {label}
              </LinkSecondary>
            ) : (
              label
            )
          }
        />
      ))}
    </Stack>
  );
};
