import { Stack, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { AssuredPackageVersionResource } from '@endorlabs/endor-core/AssuredPackageVersion';
import { FindingResource } from '@endorlabs/endor-core/Finding';
import {
  AttributeDisplayStack,
  DiffStat,
  FindingNameDisplay,
  IconCheck,
  IconX,
  NumberDisplay,
  RelativeTimeDisplay,
  SimplePagination,
  TitleActionHeader,
  useDataTablePaginator,
} from '@endorlabs/ui-common';

import {
  DetailDrawerDivider,
  DetailDrawerSection,
  DetailDrawerSectionStack,
} from '../../../../components/DetailDrawer';
import { AssuredPackageVersionFindingCountArrayDisplay } from '../AssuredPackageVersionFindingCountArrayDisplay';
import { AssuredPackageVersionPatchAttestations } from '../AssuredPackageVersionPatchAttestations';

const METADATA_ATTRIBUTE_RECORDS = [
  {
    attributeKey: 'fixedFindings',
    heading: <FM defaultMessage="Fixed Vulnerabilities" />,
  },
  { attributeKey: 'versionAge', heading: <FM defaultMessage="Version Age" /> },
  { attributeKey: 'testsRun', heading: <FM defaultMessage="Tests Run" /> },
  {
    attributeKey: 'lineOfCodeChanges',
    heading: <FM defaultMessage="Line of Code Changes" />,
  },
];

export const AssuredPackageVersionDetailDrawerOverview = ({
  assuredPackageVersion,
}: {
  assuredPackageVersion: AssuredPackageVersionResource;
}) => {
  const { space } = useTheme();

  const metadataResource = useMemo(() => {
    const upgradeSummary = assuredPackageVersion?.spec.upgrade_summary;
    const totalFixedVulns = Object.values(
      upgradeSummary?.fixed_vulns ?? {}
    ).reduce((acc, lvl) => {
      return acc + (lvl.vulns ?? []).length;
    }, 0);

    return {
      fixedFindings: upgradeSummary?.fixed_vulns ? (
        <NumberDisplay value={totalFixedVulns} />
      ) : undefined,

      versionAge: assuredPackageVersion?.meta.create_time ? (
        <RelativeTimeDisplay
          formatStyle="long"
          value={assuredPackageVersion.meta.create_time}
        />
      ) : undefined,

      testsRun: assuredPackageVersion?.spec.test_attestation?.are_tests_run ? (
        <IconCheck color="success" />
      ) : (
        <IconX color="error" />
      ),

      lineOfCodeChanges: upgradeSummary ? (
        <DiffStat
          added={upgradeSummary.line_added ?? 0}
          removed={upgradeSummary.line_removed ?? 0}
        />
      ) : undefined,
    };
  }, [
    assuredPackageVersion.meta.create_time,
    assuredPackageVersion?.spec.test_attestation?.are_tests_run,
    assuredPackageVersion?.spec.upgrade_summary,
  ]);

  const findingEntries = useMemo(() => {
    const fixedVulnsByLevel =
      assuredPackageVersion?.spec.upgrade_summary?.fixed_vulns ?? {};

    let findingEntries: FindingResource[] = [];

    Object.entries(fixedVulnsByLevel).forEach(([level, vulnsObj]) => {
      const { vulns } = vulnsObj;

      const findingsAtLevel = (vulns ?? []).map((vuln) => {
        return {
          meta: {
            description: [vuln.meta.name, vuln.meta.description].join(': '),
          },
          spec: {
            finding_metadata: { vulnerability: vuln },
            level,
          },
        } as FindingResource;
      });

      findingEntries = findingEntries.concat(findingsAtLevel);
    });

    return findingEntries;
  }, [assuredPackageVersion?.spec.upgrade_summary?.fixed_vulns]);

  const paginator = useDataTablePaginator({
    totalCount: findingEntries.length,
  });
  const findingEntriesSlice = paginator.getPageSlice(findingEntries);

  return (
    <DetailDrawerSectionStack divider={<DetailDrawerDivider fullWidth />}>
      <DetailDrawerSection>
        <Stack gap={space.sm}>
          <AttributeDisplayStack
            variant="row"
            attributeRecords={METADATA_ATTRIBUTE_RECORDS.slice(0, 3)}
            resource={metadataResource}
          />

          {/* Remainging attributes split to new line */}
          <AttributeDisplayStack
            attributeRecords={METADATA_ATTRIBUTE_RECORDS.slice(3)}
            resource={metadataResource}
          />
        </Stack>
      </DetailDrawerSection>

      <DetailDrawerSection title={<FM defaultMessage="Patch Attestations" />}>
        <AssuredPackageVersionPatchAttestations
          assuredPackageVersion={assuredPackageVersion}
        />
      </DetailDrawerSection>

      <DetailDrawerSection
        title={<FM defaultMessage="Fixed Vulnerabilities" />}
      >
        <Stack gap={space.sm}>
          <TitleActionHeader
            action={<SimplePagination paginator={paginator} />}
            title={
              <AssuredPackageVersionFindingCountArrayDisplay
                assuredPackageVersion={assuredPackageVersion}
              />
            }
          />

          <Stack rowGap={space.sm}>
            {findingEntriesSlice.map((f, index) => (
              <FindingNameDisplay key={index} finding={f} showCve />
            ))}
          </Stack>
        </Stack>
      </DetailDrawerSection>
    </DetailDrawerSectionStack>
  );
};
