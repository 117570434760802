import { defineMessages, MessageDescriptor } from 'react-intl';

import { RemediationRecommendationType } from '../constants';

export const RemediationRecommendationTypeMessages: Record<
  RemediationRecommendationType,
  MessageDescriptor
> = defineMessages({
  [RemediationRecommendationType.Patch]: { defaultMessage: 'Patch' },
  [RemediationRecommendationType.Upgrade]: { defaultMessage: 'Upgrade' },
});
