import {
  Card,
  CardActions,
  CardContent,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import {
  ButtonLinkSecondary,
  RowStack,
  UIPackageVersionUtils,
} from '@endorlabs/ui-common';

import { RemediationRecommendationType } from '../../constants';
import { RemediationRisk } from '../../types';
import { RemediationRecommendationTypeLabel } from '../RemediationRecommendationTypeLabel';
import { RemediationRiskDisplay } from '../RemediationRiskDisplay';
import {
  VersionUpgradeDisplay,
  VersionUpgradeDisplayProps,
} from '../VersionUpgradeDisplay';

export type RemediationRecommendationDisplayProps =
  VersionUpgradeDisplayProps & {
    actions?: React.ReactNode;
    display?: 'card' | 'inline';
    packageName?: string;
    recommendation?: RemediationRecommendationType;
    remediationRisk?: string;
    url?: string;
  };

export const RemediationRecommendationDisplay = ({
  actions,
  display = 'card',
  fromVersion,
  packageName,
  recommendation,
  toVersion,
  remediationRisk,
  size = 'small',
  url,
}: RemediationRecommendationDisplayProps) => {
  const theme = useTheme();
  const sx = styles(theme);

  const parsedPackageName =
    UIPackageVersionUtils.parsePackageName(packageName).label;

  const recommendationContent = (
    <RowStack flexWrap="wrap">
      <RowStack gap={1}>
        <Typography component="span" variant="inherit">
          <RemediationRecommendationTypeLabel value={recommendation} />
        </Typography>

        {parsedPackageName && (
          <Typography component="span" variant="inherit">
            {parsedPackageName}
          </Typography>
        )}
      </RowStack>

      <RowStack>
        {fromVersion && toVersion && (
          <VersionUpgradeDisplay
            fromVersion={fromVersion}
            toVersion={toVersion}
            size={size}
          />
        )}

        {remediationRisk && (
          <Typography component="span" variant="inherit">
            <FM
              defaultMessage="with {RemediationRisk} Remediation Risk"
              values={{
                RemediationRisk: (
                  <RemediationRiskDisplay
                    remediationRisk={remediationRisk as RemediationRisk}
                  />
                ),
              }}
            />
          </Typography>
        )}
      </RowStack>

      {url && (
        <ButtonLinkSecondary
          linkProps={{ to: url }}
          sx={{ alignSelf: 'flex-start' }}
        >
          <FM defaultMessage="View Details" />
        </ButtonLinkSecondary>
      )}
    </RowStack>
  );

  if (display === 'inline') {
    return recommendationContent;
  }

  return (
    <Card elevation={0} variant="outlined" sx={sx.container}>
      <CardContent sx={sx.content}>{recommendationContent}</CardContent>
      {actions && <CardActions sx={sx.actions}>{actions}</CardActions>}
    </Card>
  );
};

function styles({ palette, space, spacing }: Theme) {
  return {
    container: {
      borderColor: palette.design.severity.safe.main ?? '',
      display: 'flex',
      flexWrap: 'wrap',
      gap: space.sm,
      padding: space.sm,

      '& .MuiCardContent-root:last-child': {
        paddingBottom: 0,
      },
    },

    content: {
      alignContent: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: spacing(2, 1),
      padding: 0,
    },

    actions: { padding: 0 },
  };
}
