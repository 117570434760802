import { useTheme } from '@mui/material';
import produce from 'immer';
import _get from 'lodash-es/get';
import _groupBy from 'lodash-es/groupBy';
import { useIntl } from 'react-intl';

import { AnyTenantResourceType, ResourceKind } from '@endorlabs/endor-core';
import { getAggregatedGroupResponseData } from '@endorlabs/endor-core/api';
import { FILTER_COMPARATORS } from '@endorlabs/filters';
import {
  Color,
  ResourceKindPluralMessages,
  UIPackageVersionUtils,
  UIProjectUtils,
} from '@endorlabs/ui-common';

import {
  getDependencyPath,
  getPackageVersionPath,
  getProjectPath,
} from '../../../routes';
import {
  DashboardChartFilter,
  DashboardChartFilterKey,
  DashboardChartFilterPrimaryKeyMap,
  DashboardChartFilters,
} from '../constants/DashboardChartFilters';
import { DashboardBucketContextChartMessageKeys } from '../constants/DashboardCharts';
import { DashboardViewRecord } from '../types';
import {
  genBarChartData,
  generateInterimChartData,
  getChartColors,
} from '../utils';
import { useDashboardPreferences } from './useDashboardPreferences';
import {
  DashboardQueryAttributes,
  useDashboardQueries,
} from './useDashboardQueries';

const EMPTY_OBJECT = {};

interface UseDashboardStateReturnValue {
  changeActiveViewFilter: (
    viewId: string,
    newFilter: DashboardChartFilterKey
  ) => void;
  getActiveViewFilter: (viewId: string) => DashboardChartFilter | undefined;
  getAvailableFilters: (viewId: string) => DashboardChartFilter[][];
  getCategoryMessages: (viewId: string) => Map<string, string>;
  getView: (viewId: string) => DashboardViewRecord | undefined;
  getViewColors: (viewId: string) => Color[] | undefined;
  getViewIsLoading: (viewId: string) => boolean;
  getViewRecords: (viewId: string) => Record<string, string | number>[];
}

interface UseDashboardStateProps {
  namespace: string;
}

export const useDashboardState = ({
  namespace,
}: UseDashboardStateProps): UseDashboardStateReturnValue => {
  const intl = useIntl();
  const { palette } = useTheme();

  // Get primary query actions
  const { getQueryResult, getQueryStatus } = useDashboardQueries({
    namespace,
  });

  // Get dashboard layout variables from persisted state
  const { bucketContext, views, setDashboardPreferences } =
    useDashboardPreferences();

  const getView = (viewId: string): DashboardViewRecord | undefined => {
    return views.find((c) => c.viewId === viewId);
  };

  const getActiveViewFilter = (viewId: string) => {
    const view = getView(viewId);

    return DashboardChartFilters.find(
      (filterDef) => filterDef.key === view?.filterKeyActive
    );
  };

  const getAvailableFilters = (viewId: string) => {
    const view = getView(viewId);
    if (!view) return [];

    const filters = DashboardChartFilters.filter((f) =>
      f.allowedResourceKindsPrimary.includes(view?.resourceKindPrimary)
    );
    return Object.values(_groupBy(filters, 'allowSubcategories'));
  };

  const getNonCategoricalKeyMap = (viewId: string) => {
    const activeFilter = getActiveViewFilter(viewId);
    if (!activeFilter) return undefined;

    const { resourceKindCategory } = activeFilter;
    return new Map([
      [resourceKindCategory, ResourceKindPluralMessages[resourceKindCategory]],
    ]);
  };

  const getCategoryMessages = (viewId: string): Map<string, string> => {
    const activeFilter = getActiveViewFilter(viewId);
    if (!activeFilter) return new Map();

    const { allowSubcategories, resourceKindCategory } = activeFilter;

    const messageKeys = allowSubcategories
      ? _get(DashboardBucketContextChartMessageKeys, [
          bucketContext,
          resourceKindCategory,
        ])
      : getNonCategoricalKeyMap(viewId);

    const translatedMessageMap = new Map();
    for (const [messageKey, message] of messageKeys) {
      translatedMessageMap.set(messageKey, intl.formatMessage(message));
    }

    return translatedMessageMap;
  };

  const getViewColors = (viewId: string) => {
    const activeFilter = getActiveViewFilter(viewId);

    if (!activeFilter) {
      return getChartColors(undefined, palette);
    }

    if (activeFilter.allowSubcategories) {
      return getChartColors(bucketContext, palette);
    }

    return getChartColors(undefined, palette);
  };

  const getPrimaryKeyAttr = (viewId: string) => {
    const view = getView(viewId);
    if (!view) return undefined;

    const activeViewFilter = getActiveViewFilter(viewId);
    if (!activeViewFilter) return undefined;

    return _get(DashboardChartFilterPrimaryKeyMap, [
      activeViewFilter.resourceKindCategory,
      view.resourceKindPrimary,
    ]);
  };

  const getCategoryQueryAttributes = (viewId: string) => {
    const view = getView(viewId);
    if (!view) return undefined;

    const activeViewFilter = getActiveViewFilter(viewId);
    if (!activeViewFilter) return undefined;

    const primaryKeyAttr = getPrimaryKeyAttr(viewId);
    const isFinding = activeViewFilter.resourceKindCategory === 'Finding';

    // TODO: Probably move this into a shared constant
    const baseFilters = isFinding ? ['meta.parent_kind==PackageVersion'] : [];
    const aggregation_paths = isFinding ? ['spec.level'] : [];
    if (primaryKeyAttr) aggregation_paths.push(primaryKeyAttr);

    return {
      aggregation_paths,
      filters: activeViewFilter.expression
        ? [activeViewFilter.expression].concat(baseFilters)
        : baseFilters,
      resourceKind: activeViewFilter.resourceKindCategory,
    };
  };

  const getPrimaryRecordName = (
    resourceKind: ResourceKind,
    data: AnyTenantResourceType
  ) => {
    switch (resourceKind) {
      case 'Project':
        return (
          UIProjectUtils.parseProjectName(
            data.meta.name,
            data.spec?.platform_source
          ) ?? data.meta.name
        );
      case 'PackageVersion':
        return UIPackageVersionUtils.stripEcosystemPrefix(data.meta.name);
      case 'DependencyMetadata':
        return UIPackageVersionUtils.stripEcosystemPrefix(data.meta.name);
      default:
        return data.meta.name;
    }
  };

  const getPrimaryRecordUrl = (
    resourceKind: ResourceKind,
    data: AnyTenantResourceType
  ) => {
    const resourceNamespace = data.tenant_meta.namespace;
    switch (resourceKind) {
      case 'Project':
        return getProjectPath({
          tenantName: resourceNamespace,
          uuid: data.uuid,
        });

      case 'PackageVersion': {
        return getPackageVersionPath({
          tenantName: resourceNamespace,
          uuid: data.uuid,
        });
      }

      default:
        return undefined;
    }
  };

  const getPrimaryRecords = (viewId: string, primaryRecordKeys: string[]) => {
    const view = getView(viewId);

    // Handle: primary records from DependencyMetadata
    if (view?.resourceKindPrimary === 'DependencyMetadata') {
      return primaryRecordKeys.map((key) => ({
        key,
        name: UIPackageVersionUtils.stripEcosystemPrefix(key),
        url: getDependencyPath({
          tenantName: namespace,
          filterValues: [
            {
              key: 'meta.name',
              comparator: FILTER_COMPARATORS.EQUAL,
              value: key,
            },
          ],
        }),
      }));
    }

    const attrs = getPrimaryQueryAttributes(viewId, primaryRecordKeys);
    const data = getQueryResult(attrs);

    const records: AnyTenantResourceType[] =
      data?.spec?.query_response?.list?.objects ?? [];

    return records.map((r) => ({
      key: r.uuid,
      name: getPrimaryRecordName(attrs.resourceKind, r),
      url: getPrimaryRecordUrl(attrs.resourceKind, r),
    }));
  };

  const getPrimaryRecordMask = (resourceKind: ResourceKind) => {
    const base = [
      'meta.name',
      'meta.parent_uuid',
      'tenant_meta.namespace',
      'uuid',
    ];

    switch (resourceKind) {
      case 'Project':
        base.push('spec.platform_source');
        break;
    }

    return base.join(',');
  };

  const getPrimaryQueryAttributes = (
    viewId: string,
    primaryRecordKeys: string[] = []
  ): DashboardQueryAttributes => {
    const view = getView(viewId);
    if (!view) return { resourceKind: 'Project' as ResourceKind };

    const { resourceKindPrimary } = view;

    return {
      filters:
        primaryRecordKeys.length > 0
          ? [`uuid in ["${primaryRecordKeys.join('","')}"]`]
          : [],
      mask: getPrimaryRecordMask(resourceKindPrimary),
      resourceKind: resourceKindPrimary,
    };
  };

  // Transform category data into interim records and return top N based on total count
  const getTopNInterimData = (viewId: string) => {
    const view = getView(viewId);
    if (!view) return [];

    const attrs = getCategoryQueryAttributes(viewId);
    if (!attrs) return [];

    const primaryKeyAttr = getPrimaryKeyAttr(viewId);
    const data = getQueryResult(attrs);
    const groups =
      data?.spec?.query_response?.group_response.groups ?? EMPTY_OBJECT;

    const records = getAggregatedGroupResponseData(groups, {
      primaryKey: primaryKeyAttr,
    });

    return generateInterimChartData({
      // FIXME: Will need to change for priority buckets
      categoryKeyAttr: 'spec.level',
      categoryMessages: getCategoryMessages(viewId),
      primaryKeyAttr,
      records,
    });
  };

  const getViewRecords = (viewId: string) => {
    const interimChartData = getTopNInterimData(viewId);
    const primaryRecordKeys = interimChartData.map((d) => d.primaryKey);
    const primaryRecords = getPrimaryRecords(viewId, primaryRecordKeys);

    const categoryMessages = getCategoryMessages(viewId);
    const chartRecords = genBarChartData({
      categoryMessages,
      interimChartData,
      primaryRecords,
    });

    return chartRecords as Record<string, string | number>[];
  };

  const getViewIsLoading = (viewId: string) => {
    const view = getView(viewId);
    const activeFilter = getActiveViewFilter(viewId);
    const categoryQueryAttrs = getCategoryQueryAttributes(viewId);
    if (!view || !activeFilter || !categoryQueryAttrs) return false;

    const categoryQueryStatus = getQueryStatus(categoryQueryAttrs);

    // Handle: primary records from DependencyMetadata
    if (view.resourceKindPrimary === 'DependencyMetadata') {
      return categoryQueryStatus?.isFetching ?? false;
    }

    const topN = getTopNInterimData(viewId);
    const primaryRecordKeys = topN.map((d) => d.primaryKey);
    const primaryQueryStatus = primaryRecordKeys.length
      ? getQueryStatus(getPrimaryQueryAttributes(viewId, primaryRecordKeys))
      : undefined;

    return (
      (categoryQueryStatus?.isFetching || primaryQueryStatus?.isFetching) ??
      false
    );
  };

  const changeActiveViewFilter = (
    viewId: string,
    newFilterKey: DashboardChartFilterKey
  ) => {
    const newViews = produce(views, (newViews) => {
      const relevantChart = newViews.find((c) => c.viewId === viewId);
      if (relevantChart) {
        relevantChart.filterKeyActive = newFilterKey;
      }
    });

    setDashboardPreferences({ bucketContext, views: newViews });
  };

  return {
    changeActiveViewFilter,
    getActiveViewFilter,
    getAvailableFilters,
    getView,
    getViewRecords,
    getViewIsLoading,
    getCategoryMessages,
    getViewColors,
  };
};
