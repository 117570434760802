import { defineMessage } from 'react-intl';

import { createFileRoute } from '../../../utils/tanstack-react-router-shim';

const ROUTE_TITLE = defineMessage({
  defaultMessage: 'Remediations',
});


export const Route = createFileRoute('/t/:namespace/remediations/')({
  beforeLoad: () => ({ title: ROUTE_TITLE }),
});
