import { defineMessage } from 'react-intl';

import { createFileRoute } from '../../../utils/tanstack-react-router-shim';

const ROUTE_TITLE = defineMessage({
  defaultMessage: 'Remediation Detail',
});

export const Route = createFileRoute(
  '/t/:namespace/remediations/:version_upgrade_uuid'
)({
  beforeLoad: () => ({ title: ROUTE_TITLE }),
});
