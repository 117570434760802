import { AssuredPackageVersionResource } from '@endorlabs/endor-core/AssuredPackageVersion';

import {
  DetailDrawerSection,
  DetailDrawerSectionStack,
} from '../../../components/DetailDrawer';
import { AssuredPackageVersionPatchDetail } from './AssuredPackageVersionPatchDetail';
import { AssuredPackageVersionPatchSummary } from './AssuredPackageVersionPatchSummary';

export const AssuredPackageVersionPatchesSection = ({
  assuredPackageVersion,
}: {
  assuredPackageVersion?: AssuredPackageVersionResource;
}) => {
  return (
    <DetailDrawerSectionStack>
      <DetailDrawerSection disableGutters>
        <AssuredPackageVersionPatchSummary
          assuredPackageVersion={assuredPackageVersion}
        />
      </DetailDrawerSection>

      <DetailDrawerSection disableGutters>
        <AssuredPackageVersionPatchDetail
          assuredPackageVersion={assuredPackageVersion}
        />
      </DetailDrawerSection>
    </DetailDrawerSectionStack>
  );
};
