import { Navigate, Route } from '@tanstack/react-location';

import { NAMESPACES } from '@endorlabs/endor-core/Namespace';

import { EndorctlPage, LoginSuccessPage } from '../domains/Auth/components';
import { LoginPage } from '../domains/Auth/components/LoginPage';
import { SignupPage } from '../domains/Auth/components/SignupPage';
import {
  EditPolicyPage,
  PoliciesIndexPage,
  ViewCiCdToolRulesPage,
} from '../domains/Policies';
import { AuthInfoProvider } from '../providers/AuthInfo';
import {
  AccessControlIndexPage,
  CustomNotificationTemplatePage,
  DependenciesIndexPage,
  DependencyDetailPage,
  ErrorPage,
  FindingDetailPage,
  FindingsIndexPage,
  InstallSuccessPage,
  IntegrationsPage,
  InvitationAcceptPage,
  OnboardingIndexPage,
  PackageDetailPage,
  PackageManagerSettingsPage,
  PackagesIndexPage,
  PublicHomePage,
  ServiceRequestCreatePage,
  ServiceRequestDetail,
  SourceControlSettingsPage,
  TenantsIndexPage,
} from '../views';
import { DashboardIndexPage } from '../views/Dashboard/DashboardIndexPage';
import { NotificationTargetSettingsPage } from '../views/Integrations/NotificationTargetSettingsPage';
import { NoLicenseIndexPage } from '../views/LicenseRequired';
import { OSSExplorerPage } from '../views/OSS';
import { ServiceRequestIndexPage } from '../views/ServiceRequest/ServiceRequestIndexPage';
import { NoTenantAccount } from '../views/Tenants';
import { Route as AdminFeatureFlagsRoute } from './admin/feature-flags.route';
import { Route as AdminIndexRoute } from './admin/index.route';
import { Route as AdminRoute } from './admin/route';
import { AuthenticatedRouteGuard } from './components/AuthenticatedRouteGuard';
import { DependencyRedirectByName } from './components/DependencyRedirectByName';
import { PackageVersionRedirectByName } from './components/PackageVersionRedirectByName';
import { SecureRoute } from './components/SecureRoute';
import { NamedRoutes } from './constants';
import { PageTitles } from './constants/PageTitles';
import { Route as LoginErrorRoute } from './login-error.route';
import { Route as RedirectDocsRoute } from './redirect/docs.route';
import { Route as RedirectTenantRoute } from './redirect/t.route';
import { Route as TenantArtifactDetailRoute } from './t/$namespace/artifacts/$artifact_name.route';
import { Route as TenantArtifactsIndexRoute } from './t/$namespace/artifacts/index.route';
import { Route as TenantArtifactsRoute } from './t/$namespace/artifacts/route';
import { Route as TenantExplorePatchDetailRoute } from './t/$namespace/explore/patches/$.route';
import { Route as TenantExplorePatchesIndexRoute } from './t/$namespace/explore/patches/index.route';
import { Route as TenantProjectDetailIndexRoute } from './t/$namespace/projects/$project_uuid/index.route';
import { Route as TenantProjectPRRunDetailIndexRoute } from './t/$namespace/projects/$project_uuid/pr-runs/$pr_run_id/index.route';
import { Route as TenantProjectDetailRoute } from './t/$namespace/projects/$project_uuid/route';
import { Route as TenantProjectVersionDetailFindingsRoute } from './t/$namespace/projects/$project_uuid/versions/$version_ref/findings.route';
import { Route as TenantProjectVersionDetailIndexRoute } from './t/$namespace/projects/$project_uuid/versions/$version_ref/index.route';
import { Route as TenantProjectVersionDetailInventoryRoute } from './t/$namespace/projects/$project_uuid/versions/$version_ref/inventory.route';
import { Route as TenantProjectVersionDetailRoute } from './t/$namespace/projects/$project_uuid/versions/$version_ref/route';
import { Route as TenantProjectVersionDetailSettingsRoute } from './t/$namespace/projects/$project_uuid/versions/$version_ref/settings.route';
import { Route as TenantProjectVersionsIndexRoute } from './t/$namespace/projects/$project_uuid/versions/index.route';
import { Route as TenantProjectsIndexRoute } from './t/$namespace/projects/index.route';
import { Route as TenantProjectCreateGitHubRoute } from './t/$namespace/projects/new/github.route';
import { Route as TenantProjectCreateGitlabRoute } from './t/$namespace/projects/new/gitlab.route';
import { Route as TenantProjectCreateIndexRoute } from './t/$namespace/projects/new/index.route';
import { Route as TenantProjectCreateRoute } from './t/$namespace/projects/new/route';
import { Route as TenantProjectsRoute } from './t/$namespace/projects/route';
import { Route as TenantRemediationDetailRoute } from './t/$namespace/remediations/$version_upgrade_uuid.route';
import { Route as TenantRemediationsIndexRoute } from './t/$namespace/remediations/index.route';
import { Route as TenantRemediationsRoute } from './t/$namespace/remediations/route';
import { Route as TenantSettingsIndexRoute } from './t/$namespace/settings/index.route';
import { Route as TenantSettingsLicenseRoute } from './t/$namespace/settings/license.route';
import { Route as TenantSettingsRoute } from './t/$namespace/settings/route';
import { Route as TenantSettingsSavedFiltersRoute } from './t/$namespace/settings/saved-filters.route';
import { Route as TenantSettingsScanProfilesRoute } from './t/$namespace/settings/scan-profiles.route';
import { Route as TenantSettingsSystemRoute } from './t/$namespace/settings/system.route';
import { Route as TenantThirdPartyIndexRoute } from './t/$namespace/third-party/index.route';
import { Route as TenantThirdPartyRoute } from './t/$namespace/third-party/route';
import { Route as TenantThirdPartySbomsDetailRoute } from './t/$namespace/third-party/sboms/$sbom_uuid.route';
import { Route as TenantThirdPartySbomsExportRoute } from './t/$namespace/third-party/sboms/export.route';
import { Route as TenantThirdPartySbomsImportRoute } from './t/$namespace/third-party/sboms/import.route';
import { Route as TenantThirdPartySbomsIndexRoute } from './t/$namespace/third-party/sboms/index.route';
import { Route as TenantThirdPartySbomsRoute } from './t/$namespace/third-party/sboms/route';
import { Route as TenantToolsIndexRoute } from './t/$namespace/tools/index.route';
import { Route as TenantToolsRoute } from './t/$namespace/tools/route';
import { Route as TenantCreateIndexRoute } from './tenant-create/index.route';
import { Route as TenantCreateRoute } from './tenant-create/route';
import {
  withLazyRoute,
  withRouteChildren,
} from './utils/tanstack-react-router-shim';

/**
 * Route subtree for dashboard-related pages
 */
const DashboardRoutes: Route[] = [
  {
    path: 'dashboard',
    // prevent navigation to the Dashboard page for the oss tenant
    element: <AuthenticatedRouteGuard />,
    children: [
      {
        path: '/',
        element: <DashboardIndexPage />,
        meta: {
          pageTitle: PageTitles.dashboardIndexPage,
        },
      },
    ],
  },
];

/**
 * Route subtree for package-related pages
 */
const PackageRoutes: Route[] = [
  {
    path: 'packages',
    element: <AuthenticatedRouteGuard />,
    children: [
      {
        path: '/',
        element: <PackagesIndexPage />,
        meta: {
          pageTitle: PageTitles.packagesIndexPage,
        },
      },
      {
        path: 'redirect-by-name/:packageVersionName',
        element: <PackageVersionRedirectByName />,
      },
      {
        path: ':packageVersionUuid',
        element: <PackageDetailPage />,
        children: [{ path: ':activeView' }],
        meta: {
          pageTitle: PageTitles.packagesDetailPage,
        },
      },
    ],
  },
];

/**
 * Route subtree for dependency-related pages
 */
const DependencyRoutes: Route[] = [
  {
    path: 'dependencies',
    // prevent navigation to the Dependencies page for the oss tenant
    element: <AuthenticatedRouteGuard />,
    children: [
      {
        path: '/',
        element: <DependenciesIndexPage />,
        meta: {
          pageTitle: PageTitles.dependenciesIndexPage,
        },
      },
      {
        path: 'redirect-by-name/:dependencyPackageVersionName',
        element: <DependencyRedirectByName />,
      },
      {
        path: ':dependencyUuid',
        element: <DependencyDetailPage />,
        children: [{ path: ':activeView' }],
        meta: {
          pageTitle: PageTitles.dependencyDetailPage,
        },
      },
    ],
  },
];

/**
 * Route subtree for Findings entry and related pages
 */
const FindingsRoutes: Route[] = [
  {
    path: 'findings',
    element: <AuthenticatedRouteGuard />,
    children: [
      // handle redirect in base case
      {
        path: '/',
        element: <Navigate to="dependency" replace />,
      },
      // qualify finding aggregation urls, to avoid conflicts with finding detail :uuid route
      {
        path: 'dependency',
        element: <FindingsIndexPage activeView="dependency" />,
        meta: {
          pageTitle: PageTitles.findingsIndexPage,
        },
      },
      {
        path: 'package',
        element: <FindingsIndexPage activeView="package" />,
        meta: {
          pageTitle: PageTitles.findingsIndexPage,
        },
      },
      {
        path: 'repository',
        element: <FindingsIndexPage activeView="repository" />,
        meta: {
          pageTitle: PageTitles.findingsIndexPage,
        },
      },
      {
        path: 'secrets',
        element: <FindingsIndexPage activeView="secrets" />,
        meta: {
          pageTitle: PageTitles.findingsIndexPage,
        },
      },
      {
        path: ':findingUuid',
        element: <FindingDetailPage />,
        children: [{ path: ':activeView' }],
        meta: {
          pageTitle: PageTitles.findingDetailPage,
        },
      },
    ],
  },
];

const AccessControlRoutes: Route[] = [
  {
    path: 'access',
    element: <AuthenticatedRouteGuard />,
    children: [
      { path: '/', element: <Navigate to="authorization-policies" replace /> },
      {
        element: <AccessControlIndexPage />,
        children: [{ path: ':activeView' }],
        meta: {
          pageTitle: PageTitles.settingsPage,
        },
      },
    ],
  },
];

/**
 * Route subtree for Service-Request Displays
 */
const ServiceRequestRoutes = [
  {
    path: 'service-requests',
    // prevent navigation to the ServiceRequest page for the oss tenant
    element: <AuthenticatedRouteGuard />,
    children: [
      {
        path: '/',
        element: <ServiceRequestIndexPage />,
        meta: {
          pageTitle: PageTitles.serviceRequestIndexPage,
        },
      },
      {
        path: 'new',
        element: <ServiceRequestCreatePage />,
        meta: {
          pageTitle: PageTitles.serviceRequestIndexPage,
        },
      },
      {
        path: ':serviceRequestUuid',
        element: <ServiceRequestDetail />,
        meta: {
          pageTitle: PageTitles.serviceRequestDetailPage,
        },
      },
    ],
  },
];

const PolicyRoutes: Route[] = [
  {
    path: 'policies',
    element: <AuthenticatedRouteGuard />,
    children: [
      {
        path: '/',
        element: <PoliciesIndexPage />,
        meta: { pageTitle: PageTitles.policiesIndexPage },
      },
      {
        path: ':activeView',
        children: [
          {
            path: '/',
            element: <PoliciesIndexPage />,
            meta: { pageTitle: PageTitles.policiesIndexPage },
          },
          {
            path: 'new',
            element: <EditPolicyPage />,
            meta: { pageTitle: PageTitles.policiesNewPage },
          },
          {
            path: 'edit/:policyUuid',
            element: <EditPolicyPage />,
            meta: { pageTitle: PageTitles.policiesEditPage },
          },
          {
            path: 'view/:activeUuid',
            element: <ViewCiCdToolRulesPage />,
            meta: { pageTitle: PageTitles.ciCdToolRulesViewPage },
          },
        ],
      },
    ],
  },
];

const IntegrationsRoutes: Route[] = [
  {
    path: 'integrations',
    element: <AuthenticatedRouteGuard />,
    children: [
      {
        path: '/',
        element: <IntegrationsPage />,
        meta: {
          pageTitle: PageTitles.integrationsPage,
        },
      },
      {
        path: 'package-managers/:packageManagerName',
        element: <PackageManagerSettingsPage />,
      },
      {
        path: 'source-control/:platformName',
        element: <SourceControlSettingsPage />,
      },
      {
        path: 'notification-targets/:actionType',
        element: <NotificationTargetSettingsPage />,
        meta: {
          pageTitle: PageTitles.notificationTargetSettingsPage,
        },
      },
      {
        path: 'custom-notification-template/:templateType',
        element: <CustomNotificationTemplatePage />,
        children: [{ path: ':uuid' }],
        meta: {
          pageTitle: PageTitles.customNotificationTemplatePage,
        },
      },
    ],
  },
];

const OnboardRoutes: Route[] = [
  {
    path: 'onboard',
    element: <OnboardingIndexPage />,
    children: [{ path: ':onboardingApproach' }],
  },
];

const NoLicenseRoutes: Route[] = [
  {
    path: 'license-required',
    element: <NoLicenseIndexPage />,
  },
];

const NotificationRoutes: Route[] = [
  {
    path: 'notifications',
    element: <AuthenticatedRouteGuard />,
    children: [
      {
        path: '/',
        element: <Navigate to="open" />,
      },
      // {
      //   path: 'view/:notificationUuid',
      //   element: <NotificationsDetailPage />,
      // },
      {
        path: ':activeView',
        element: () =>
          import(
            /* webpackChunkName: "route_notifications" */ '../domains/Notifications'
          ).then((m) => <m.NotificationsIndexPage />),
        meta: { pageTitle: PageTitles.notificationsIndexPage },
      },
    ],
  },
];

const NamespaceRoutes: Route[] = [
  {
    path: 'namespaces',
    element: () =>
      import(
        /* webpackChunkName: "route_namespaces" */ '../views/Namespaces'
      ).then((m) => <m.NamespacesPage />),
  },
];

/**
 * =============================================================================
 * Route config object used in the React Location Router
 * See https://react-location.tanstack.com/docs/api#defining-routes
 *
 * Route subtrees are broken out above for clarity, & to allow
 * inclusion in multiple locations (e.g. at Tenant & Namespace levels)
 * =============================================================================
 */
export const AppRoutes: Route[] = [
  // Publicly accessible pages
  {
    path: NamedRoutes.PUBLIC_HOME,
    element: <PublicHomePage />,
  },
  {
    path: NamedRoutes.ENDORCTL_INIT,
    element: <EndorctlPage />,
  },
  {
    path: NamedRoutes.SIGNUP,
    element: <SignupPage />,
    meta: {
      pageTitle: PageTitles.signupPage,
    },
  },
  {
    path: NamedRoutes.LOGIN,
    element: <LoginPage />,
    meta: {
      pageTitle: PageTitles.loginPage,
    },
  },
  {
    path: NamedRoutes.LOGIN_SUCCESS,
    element: <LoginSuccessPage />,
  },
  LoginErrorRoute,
  { path: NamedRoutes.INSTALL_SUCCESS, element: <InstallSuccessPage /> },
  {
    path: NamedRoutes.INVITATION_ACCEPT,
    element: <InvitationAcceptPage />,
  },

  // Routes below require login
  {
    path: NamedRoutes.OSS_EXPLORER,
    element: (
      <AuthInfoProvider>
        <SecureRoute />
      </AuthInfoProvider>
    ),
    children: [
      {
        path: '/',
        element: <OSSExplorerPage />,
        meta: {
          pageTitle: PageTitles.ossIndexPage,
        },
      },
      {
        path: 'packages/redirect-by-name/:packageVersionName',
        element: <PackageVersionRedirectByName />,
      },
      {
        path: 'packages/:packageVersionUuid',
        element: <PackageDetailPage />,
        children: [{ path: ':activeView' }],
        meta: {
          pageTitle: PageTitles.packagesDetailPage,
        },
      },
      {
        path: 'packages',
        element: <Navigate to={NamedRoutes.OSS_EXPLORER} />,
      },
    ],
  },

  withRouteChildren(TenantCreateRoute, [TenantCreateIndexRoute]),

  {
    path: NamedRoutes.NO_ACCOUNT,
    element: (
      <AuthInfoProvider>
        <SecureRoute />
      </AuthInfoProvider>
    ),
    children: [
      {
        path: '/',
        element: <NoTenantAccount />,
        meta: { pageTitle: PageTitles.noAccountPage },
      },
    ],
  },
  {
    path: 't',
    element: (
      <AuthInfoProvider>
        <SecureRoute />
      </AuthInfoProvider>
    ),
    children: [
      { path: '/', element: <TenantsIndexPage /> },
      {
        // Redirect from `/t/oss` to the OSS Explorer
        path: NAMESPACES.OSS,
        element: <Navigate to={NamedRoutes.OSS_EXPLORER} />,
      },
      {
        path: ':namespace',
        // Include resource pages at Tenant level
        children: [
          // redirect from tenant root to default tenant page
          {
            path: '/',
            element: <TenantsIndexPage />,
          },
          withRouteChildren(
            { ...TenantArtifactsRoute, path: 'artifacts' },
            [
              withLazyRoute(TenantArtifactsIndexRoute, () =>
                import(
                  /* webpackChunkName: "route_artifacts" */
                  './t/$namespace/artifacts/index.route.lazy'
                ).then((r) => r.Route)
              ),
              withLazyRoute(TenantArtifactDetailRoute, () =>
                import(
                  /* webpackChunkName: "route_artifacts" */
                  './t/$namespace/artifacts/$artifact_name.route.lazy'
                ).then((r) => r.Route)
              ),
            ],
            { stripPrefix: '/t/:namespace/artifacts' }
          ),
          withRouteChildren(
            { path: 'explore' },
            [
              withLazyRoute(TenantExplorePatchesIndexRoute, () =>
                import(
                  /* webpackChunkName: "route_explore" */
                  './t/$namespace/explore/patches/index.route.lazy'
                ).then((r) => r.Route)
              ),
              withLazyRoute(TenantExplorePatchDetailRoute, () =>
                import(
                  /* webpackChunkName: "route_explore" */
                  './t/$namespace/explore/patches/$.route.lazy'
                ).then((r) => r.Route)
              ),
            ],
            {
              stripPrefix: '/t/:namespace/explore',
            }
          ),
          // Project Routes
          withRouteChildren(
            { ...TenantProjectsRoute, path: 'projects' },
            [
              TenantProjectsIndexRoute,
              withRouteChildren(TenantProjectCreateRoute, [
                TenantProjectCreateIndexRoute,
                TenantProjectCreateGitHubRoute,
                TenantProjectCreateGitlabRoute,
              ]),
              withRouteChildren(TenantProjectDetailRoute, [
                TenantProjectPRRunDetailIndexRoute,
                TenantProjectVersionsIndexRoute,
                withRouteChildren(TenantProjectVersionDetailRoute, [
                  TenantProjectVersionDetailIndexRoute,
                  TenantProjectVersionDetailFindingsRoute,
                  TenantProjectVersionDetailSettingsRoute,
                  TenantProjectVersionDetailInventoryRoute,
                ]),
                { ...TenantProjectDetailIndexRoute, path: undefined },
              ]),
            ],
            { stripPrefix: '/t/:namespace/projects' }
          ),
          withRouteChildren(
            { ...TenantRemediationsRoute, path: 'remediations' },
            [
              withLazyRoute(TenantRemediationsIndexRoute, () =>
                import(
                  /* webpackChunkName: "route_remediations" */
                  './t/$namespace/remediations/index.route.lazy'
                ).then((m) => m.Route)
              ),
              withLazyRoute(TenantRemediationDetailRoute, () =>
                import(
                  /* webpackChunkName: "route_remediations" */
                  './t/$namespace/remediations/$version_upgrade_uuid.route.lazy'
                ).then((m) => m.Route)
              ),
            ],
            { stripPrefix: '/t/:namespace/remediations' }
          ),
          withRouteChildren(
            { ...TenantSettingsRoute, path: 'settings' },
            [
              TenantSettingsIndexRoute,
              withLazyRoute(TenantSettingsLicenseRoute, () =>
                import(
                  /* webpackChunkName: "route_settings" */
                  './t/$namespace/settings/license.route.lazy'
                ).then((m) => m.Route)
              ),
              withLazyRoute(TenantSettingsSavedFiltersRoute, () =>
                import(
                  /* webpackChunkName: "route_settings" */ './t/$namespace/settings/saved-filters.route.lazy'
                ).then((m) => m.Route)
              ),
              withLazyRoute(TenantSettingsSystemRoute, () =>
                import(
                  /* webpackChunkName: "route_settings" */ './t/$namespace/settings/system.route.lazy'
                ).then((m) => m.Route)
              ),
              withLazyRoute(TenantSettingsScanProfilesRoute, () =>
                import(
                  /* webpackChunkName: "route_settings" */ './t/$namespace/settings/scan-profiles.route.lazy'
                ).then((m) => m.Route)
              ),
            ],
            { stripPrefix: '/t/:namespace/settings' }
          ),
          withRouteChildren(
            { ...TenantThirdPartyRoute, path: 'third-party' },
            [
              TenantThirdPartyIndexRoute,
              withRouteChildren(
                withLazyRoute(TenantThirdPartySbomsRoute, () =>
                  import(
                    /* webpackChunkName: "route_sboms" */ './t/$namespace/third-party/sboms/route.lazy'
                  ).then((m) => m.Route)
                ),
                [
                  TenantThirdPartySbomsIndexRoute,
                  withLazyRoute(TenantThirdPartySbomsExportRoute, () =>
                    import(
                      /* webpackChunkName: "route_sboms" */
                      './t/$namespace/third-party/sboms/export.route.lazy'
                    ).then((m) => m.Route)
                  ),
                  withLazyRoute(TenantThirdPartySbomsImportRoute, () =>
                    import(
                      /* webpackChunkName: "route_sboms" */
                      './t/$namespace/third-party/sboms/import.route.lazy'
                    ).then((m) => m.Route)
                  ),
                  withLazyRoute(TenantThirdPartySbomsDetailRoute, () =>
                    import(
                      /* webpackChunkName: "route_sboms" */
                      './t/$namespace/third-party/sboms/$sbom_uuid.route.lazy'
                    ).then((m) => m.Route)
                  ),
                ]
              ),
            ],
            { stripPrefix: '/t/:namespace/third-party' }
          ),
          withRouteChildren(
            { ...TenantToolsRoute, path: 'tools' },
            [
              withLazyRoute(TenantToolsIndexRoute, () =>
                import(
                  /* webpackChunkName: "route_tools" */
                  './t/$namespace/tools/index.route.lazy'
                ).then((m) => m.Route)
              ),
            ],
            { stripPrefix: '/t/:namespace/tools' }
          ),
          ...OnboardRoutes,
          ...DashboardRoutes,
          ...PackageRoutes,
          ...FindingsRoutes,
          ...DependencyRoutes,
          ...AccessControlRoutes,
          ...NotificationRoutes,
          ...PolicyRoutes,
          ...IntegrationsRoutes,
          ...ServiceRequestRoutes,
          ...NamespaceRoutes,
          ...NoLicenseRoutes,
        ],
      },
    ],
  },

  // Redirect utility pagess
  RedirectDocsRoute,
  RedirectTenantRoute,

  // General purpose error page
  {
    path: NamedRoutes.ERROR_PAGE,
    element: <ErrorPage errorCode={404} />,
    meta: {
      pageTitle: PageTitles.errorPage,
    },
  },

  // Admin page
  withRouteChildren(
    withLazyRoute(AdminRoute, () =>
      import(/* webpackChunkName: "route_admin" */ './admin/route.lazy').then(
        (m) => m.Route
      )
    ),
    [
      AdminIndexRoute,
      withLazyRoute(AdminFeatureFlagsRoute, () =>
        import(
          /* webpackChunkName: "route_admin" */ './admin/feature-flags.route.lazy'
        ).then((m) => m.Route)
      ),
    ]
  ),

  {
    path: '*',
    element: <ErrorPage errorCode={404} />,
    meta: {
      pageTitle: PageTitles.errorPage,
    },
  },
];
