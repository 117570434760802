import { defineMessages } from 'react-intl';

export const PageTitles = defineMessages({
  apiKeysPage: { defaultMessage: 'API Keys | Endor Labs' },
  assuredPackageVersionDetailPage: {
    defaultMessage: 'Magic Patch Detail | Endor Labs',
  },
  ciCdToolRulesViewPage: { defaultMessage: 'CI/CD Tool Rules | Endor Labs' },
  customNotificationTemplatePage: {
    defaultMessage: 'Custom Notification Template | Endor Labs',
  },
  dashboardIndexPage: { defaultMessage: 'Dashboard | Endor Labs' },
  dependenciesIndexPage: { defaultMessage: 'Dependencies | Endor Labs' },
  dependencyDetailPage: {
    defaultMessage: '{packageName} {packageVersion} | Endor Labs',
  },
  errorPage: {
    defaultMessage: 'Page Not Found | Endor Labs',
  },
  findingDetailPage: {
    defaultMessage: 'Finding Detail | Findings | Endor Labs',
  },
  findingsIndexPage: { defaultMessage: 'Findings | Endor Labs' },
  integrationsPage: { defaultMessage: 'Integrations | Endor Labs' },
  loginPage: { defaultMessage: 'Login | Endor Labs' },
  signupPage: { defaultMessage: 'Signup | Endor Labs' },
  noAccountPage: { defaultMessage: 'No Account | Endor Labs' },
  notificationsIndexPage: { defaultMessage: 'Notifications | Endor Labs' },
  notificationTargetSettingsPage: {
    defaultMessage: 'Notification Integrations | Endor Labs',
  },
  ossIndexPage: { defaultMessage: 'Open Source Explorer | Endor Labs' },
  packagesIndexPage: { defaultMessage: 'Packages | Endor Labs' },
  packagesDetailPage: {
    defaultMessage: '{packageName} {packageVersion} | Endor Labs',
  },
  policiesEditPage: { defaultMessage: 'Edit Policy | Endor Labs' },
  policiesIndexPage: { defaultMessage: 'Policies | Endor Labs' },
  policiesNewPage: { defaultMessage: 'New Policy | Endor Labs' },
  projectCIRunDetailPage: {
    defaultMessage: 'PR Runs | {projectName} | Projects | Endor Labs',
  },
  projectDetailPage: {
    defaultMessage: '{projectName} | Projects | Endor Labs',
  },
  settingsPage: { defaultMessage: 'Settings | Endor Labs' },

  serviceRequestIndexPage: { defaultMessage: 'Service Requests | Endor Labs' },

  serviceRequestDetailPage: {
    defaultMessage: 'Service Request Detail | Endor Labs',
  },
  thirdPartyPage: { defaultMessage: 'SBOM Hub | Endor Labs' },
  thirdPartySBOMSDetailPage: {
    defaultMessage: '{sbomDisplayName} | Endor Labs',
  },
  thirdPartySBOMSIndexPage: { defaultMessage: 'SBOMs | Endor Labs' },
});
