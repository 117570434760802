import { VersionUpgradeResource } from '@endorlabs/endor-core/VersionUpgrade';

import { useInfoDrawer } from '../../../../components/InfoDrawer';
import {
  RemediationDetailDrawer,
  RemediationDetailDrawerProps,
} from './RemediationDetailDrawer';

export interface RemediationDetailDrawerParams {
  namespace: string;
  upgradeUuid?: string;
}

interface PermalinkEffectProps {
  versionUpgrades?: VersionUpgradeResource[];
}

export const useRemediationDetailDrawer = () => {
  const DetailDrawer = useInfoDrawer<
    RemediationDetailDrawerProps,
    RemediationDetailDrawerProps
  >({
    drawerParams: ['namespace', 'upgradeUuid'],
    Component: RemediationDetailDrawer,
  });

  const permalinkEffect = ({ versionUpgrades }: PermalinkEffectProps) => {
    const activeDrawerParams = DetailDrawer.getSearchParams();
    const activeVersionUpgrade = activeDrawerParams
      ? versionUpgrades?.find((p) => p.uuid === activeDrawerParams.upgradeUuid)
      : undefined;

    if (
      activeVersionUpgrade &&
      !DetailDrawer.matchesActiveContent(activeDrawerParams)
    ) {
      const params = {
        namespace: activeVersionUpgrade.tenant_meta.namespace,
        upgradeUuid: activeVersionUpgrade.uuid,
      };
      DetailDrawer.activate(params, params);
    }
  };

  return { DetailDrawer, permalinkEffect };
};
