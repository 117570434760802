import '@endorlabs/ui-common/assets/logos/logo-endor-primary-single-line.svg';

import {
  Box,
  Button,
  CardContent,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { MouseEvent, useCallback, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { useFeatureFlags } from '@endorlabs/queries';
import {
  ButtonLogin,
  FullHeightCard,
  ImgIconLogoGoogle,
  ImgIconLogoMarkGitHub,
  ImgIconLogoMarkGitLab,
} from '@endorlabs/ui-common';
import logo from '@endorlabs/ui-common/assets/logos/logo-endor-primary-single-line.svg';

import { NamedRoutes } from '../../../routes/constants/NamedRoutes';
import {
  privacyPolicyLink,
  SuccessEmail,
  termsOfUseLink,
} from './AuthHelperComponents';
import { LoginErrorDisplay } from './LoginErrorDisplay';
import { LoginFormEmailLink } from './LoginFormEmailLink';

interface SignupFormCardProps {
  code?: string;
}

export const SignupFormCard = ({ code }: SignupFormCardProps) => {
  const theme = useTheme();

  const navigate = useNavigate();
  const [signupSuccess, setSignupSuccess] = useState(false);

  const handleEmailLinkSent = useCallback(() => {
    setSignupSuccess(true);
  }, []);

  const handleHideEmailSuccess = useCallback((event: MouseEvent) => {
    event.preventDefault();
    setSignupSuccess(false);
  }, []);

  return (
    <FullHeightCard elevation={24}>
      <CardContent>
        <Stack paddingTop={2} spacing={6} alignItems="center" width="100%">
          {signupSuccess && (
            <SuccessEmail
              onReturnLinkClick={handleHideEmailSuccess}
              returnTo={NamedRoutes.SIGNUP}
            />
          )}

          {!signupSuccess && (
            <>
              <Box
                display="flex"
                justifyContent="center"
                marginBottom={code ? 2 : 0}
                width="100%"
              >
                <img
                  src={logo}
                  alt="Endor Labs"
                  width={code ? '33%' : '100%'}
                />
              </Box>

              {code && <LoginErrorDisplay />}

              <Typography variant="body1">
                <FM defaultMessage="A business email address is required to sign up." />
              </Typography>

              <ButtonLogin
                href={`${NamedRoutes.AUTH_GOOGLE}?event=signup`}
                color="secondary"
              >
                <ImgIconLogoGoogle fontSize="medium" sx={{ marginRight: 1 }} />
                <FM defaultMessage="Sign up with Business Google" />
              </ButtonLogin>

              <ButtonLogin
                href={`${NamedRoutes.AUTH_GITHUB}?event=signup`}
                color="secondary"
              >
                <ImgIconLogoMarkGitHub
                  fontSize="medium"
                  sx={{ marginRight: 1 }}
                />
                <FM defaultMessage="Sign up with GitHub" />
              </ButtonLogin>

              <ButtonLogin
                href={`${NamedRoutes.AUTH_GITLAB}?event=signup`}
                color="secondary"
              >
                <ImgIconLogoMarkGitLab
                  fontSize="medium"
                  sx={{ marginRight: 1 }}
                />
                <FM defaultMessage="Sign up with GitLab" />
              </ButtonLogin>

              <Typography>
                <FM defaultMessage="or" />
              </Typography>

              <LoginFormEmailLink
                event="signup"
                onEmailLinkSent={handleEmailLinkSent}
              />

              <Typography
                variant="caption"
                sx={{ width: '100%' }}
                textAlign="center"
              >
                <FM
                  defaultMessage="By signing up, you agree to the Endor Labs <privacy></privacy>
        and <terms></terms>."
                  values={{
                    privacy: () => privacyPolicyLink,
                    terms: () => termsOfUseLink,
                  }}
                />
              </Typography>

              <Stack
                spacing={2}
                alignItems="center"
                sx={({ palette }) => ({
                  borderTop: '1px solid',
                  borderColor: palette.grey[300],
                  width: '100%',
                })}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.text.secondary,
                    marginTop: 6,
                  }}
                >
                  <FM defaultMessage="Already using Endor Labs?" />
                </Typography>
                <Button
                  onClick={() => navigate({ to: NamedRoutes.LOGIN })}
                  variant="text"
                  size="large"
                >
                  <FM defaultMessage="Login to your existing account" />
                </Button>
              </Stack>
            </>
          )}
        </Stack>
      </CardContent>
    </FullHeightCard>
  );
};
