import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { AssuredPackageVersionResource } from '@endorlabs/endor-core/AssuredPackageVersion';
import {
  AttributeDisplayStack,
  DiffStat,
  IconCheck,
  IconX,
  RelativeTimeDisplay,
} from '@endorlabs/ui-common';

const ATTRIBUTE_RECORDS = [
  { attributeKey: 'testsRun', heading: <FM defaultMessage="Tests Run" /> },
  {
    attributeKey: 'lineOfCodeChanges',
    heading: <FM defaultMessage="Line of Code Changes" />,
  },
  { attributeKey: 'lastScan', heading: <FM defaultMessage="Last Scan" /> },
];

export const AssuredPackageVersionOverviewAttributes = ({
  assuredPackageVersion,
  isLoading,
}: {
  assuredPackageVersion?: AssuredPackageVersionResource;
  isLoading?: boolean;
}) => {
  const resource = useMemo(() => {
    const areTestsRun =
      assuredPackageVersion?.spec.test_attestation?.are_tests_run;
    const scanTime = assuredPackageVersion?.processing_status?.scan_time;
    const upgradeSummary = assuredPackageVersion?.spec.upgrade_summary;

    return {
      lastScan: scanTime ? (
        <RelativeTimeDisplay formatStyle="long" value={scanTime} />
      ) : undefined,

      lineOfCodeChanges: upgradeSummary ? (
        <DiffStat
          added={upgradeSummary.line_added ?? 0}
          removed={upgradeSummary.line_removed ?? 0}
        />
      ) : undefined,

      testsRun:
        areTestsRun === true ? (
          <IconCheck color="success" />
        ) : areTestsRun === false ? (
          <IconX color="error" />
        ) : undefined,
    };
  }, [
    assuredPackageVersion?.processing_status?.scan_time,
    assuredPackageVersion?.spec.test_attestation?.are_tests_run,
    assuredPackageVersion?.spec.upgrade_summary,
  ]);

  return (
    <AttributeDisplayStack
      attributeRecords={ATTRIBUTE_RECORDS}
      isLoading={isLoading}
      resource={resource}
      variant="row"
    />
  );
};
