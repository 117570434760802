import { V1ReleaseUpgradeInfo } from '@endorlabs/api_client';

import {
  DetailDrawerSection,
  DetailDrawerSectionStack,
} from '../../../../components/DetailDrawer';
import { BreakingChangesOverview } from '../BreakingChangesOverview';
import { BreakingChangesSection } from '../BreakingChangesSection';

export const RemediationDetailDrawerBreakingChanges = ({
  upgradeInfo,
}: {
  upgradeInfo?: V1ReleaseUpgradeInfo;
}) => {
  return (
    <DetailDrawerSectionStack>
      <DetailDrawerSection disableGutters>
        <BreakingChangesOverview upgradeInfo={upgradeInfo} />
      </DetailDrawerSection>

      <DetailDrawerSection>
        <BreakingChangesSection upgradeInfo={upgradeInfo} />
      </DetailDrawerSection>
    </DetailDrawerSectionStack>
  );
};
