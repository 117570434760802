import { Chip, ChipProps, Theme } from '@mui/material';

import { useStyles } from '../../hooks';

interface PipProps extends ChipProps {}

export const Pip = ({ size = 'small', ...props }: PipProps) => {
  const sx = useStyles(styles);

  return (
    <Chip
      className="Pip-root"
      size={size}
      variant="filled"
      {...props}
      sx={sx}
    />
  );
};

const styles = ({ palette, spacing, typography }: Theme) => {
  const { severity } = palette.design;

  return {
    '&.MuiChip-root': {
      borderRadius: '4px',

      '&.MuiChip-filled': {
        borderStyle: 'solid',
        borderWidth: '1px',
        fontWeight: 500,
      },

      '&.MuiChip-sizeXsmall': {
        height: spacing(4.5),
        paddingX: spacing(0.5),
      },

      '&.MuiChip-sizeSmall': {
        height: spacing(5.5),
      },

      '&.MuiChip-sizeMedium': {
        height: spacing(7),
      },

      '&.MuiChip-sizeLarge': {
        height: spacing(7),
        fontSize: typography.h3.fontSize,
        '& .MuiChip-label': {
          paddingLeft: spacing(1),
          paddingRight: spacing(1),
        },
      },

      '& .MuiChip-icon': {
        marginRight: 0,
      },
    },

    // FIXME: Need separate treatment for color="primary"
    '&, &.MuiChip-colorDefault, &.MuiChip-colorPrimary': {
      backgroundColor: palette.design.grays[100],
      borderColor: palette.grey[300],
      color: palette.text.primary,
    },

    '&.MuiChip-colorSecondary': {
      backgroundColor: palette.design.grays[100],
      borderColor: palette.design.greens.dark_teal[600],
      color: palette.design.greens.dark_teal[800],
    },

    '&.MuiChip-colorWarning': {
      backgroundColor: severity.medium[50],
      borderColor: severity.high[200],
      color: severity.medium.hover,
    },

    '&.MuiChip-colorError': {
      backgroundColor: severity.high[50],
      borderColor: severity.high[200],
      color: severity.high.hover,
    },

    '&.MuiChip-colorCritical': {
      backgroundColor: severity.high[50],
      borderColor: severity.critical[200],
      color: palette.darkMode
        ? severity.critical.hover
        : severity.critical[200],
    },

    '&.MuiChip-colorInfo': {
      backgroundColor: severity.low[50],
      borderColor: severity.low.main,
      color: severity.medium.hover,
    },

    '&.MuiChip-colorSuccess': {
      backgroundColor: severity.safe[100],
      borderColor: severity.safe.main,
      color: severity.safe.hover,
    },

    '&.MuiChip-outlined.MuiChip-colorPrimary, &.MuiChip-outlined.MuiChip-colorInfo':
      {
        backgroundColor: 'transparent',
      },

    '&.MuiChip-outlined.MuiChip-colorWarning': {
      backgroundColor: 'transparent',
      borderColor: severity.medium.main,
      color: palette.text.primary,
    },

    '&.MuiChip-outlined.MuiChip-colorCritical': {
      backgroundColor: 'transparent',
      color: palette.text.primary,
    },

    '&.MuiChip-outlined.MuiChip-colorError': {
      backgroundColor: 'transparent',
      color: palette.text.primary,
    },

    '&.MuiChip-outlined.MuiChip-colorInfo': {
      backgroundColor: 'transparent',
      color: palette.text.primary,
    },

    '&.MuiChip-outlined.MuiChip-colorSuccess': {
      backgroundColor: 'transparent',
      borderColor: severity.safe.main,
      color: palette.text.primary,
    },
  };
};
