import { Divider, Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1ReleaseUpgradeInfo } from '@endorlabs/api_client';
import {
  EmptyState,
  ExpandCollapseControl,
  RowStack,
} from '@endorlabs/ui-common';

import { PotentialConflictDisplay } from '../PotentialConflictDisplay';

export const PotentialConflictsSection = ({
  upgradeInfo,
}: {
  upgradeInfo?: V1ReleaseUpgradeInfo;
}) => {
  const { space } = useTheme();
  const conflictCount =
    (upgradeInfo?.conflicts ?? 0) + (upgradeInfo?.minor_conflicts ?? 0);

  const majorConflictList = Object.values(upgradeInfo?.conflicts_map ?? {});
  const minorConflictList = Object.values(
    upgradeInfo?.minor_conflicts_map ?? {}
  );

  const conflictList = [...majorConflictList, ...minorConflictList];

  const [allExpanded, setAllExpanded] = useState<boolean>(true);

  const isEmpty = conflictCount === 0;

  if (isEmpty) {
    return (
      <EmptyState
        imageWidth={300}
        size="medium"
        title={<FM defaultMessage="No Potential Conflicts" />}
      />
    );
  }

  return (
    <Stack spacing={space.xs} paddingTop={space.sm}>
      <RowStack justifyContent="space-between" width="100%">
        <ExpandCollapseControl onClick={setAllExpanded} />
        <Typography variant="body2">
          <FM
            defaultMessage="{count} Potential Conflicts"
            values={{ count: conflictCount }}
          />
        </Typography>
      </RowStack>
      <Stack spacing={space.sm} divider={<Divider />}>
        {conflictList.map((conflict, index) => (
          <PotentialConflictDisplay
            conflict={conflict}
            isExpanded={allExpanded}
            key={index}
          />
        ))}
      </Stack>
    </Stack>
  );
};
