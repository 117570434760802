import { get as _get, set as _set } from 'lodash-es';

import { V1GroupResponse } from '@endorlabs/api_client';
import { safeParse as safeParseJSON } from '@endorlabs/utils/encoding/json';

export type AggregatedGroupResponse = Record<
  string,
  Record<string, Record<string, number>>
>;

/**
 * Module-level cache for previously aggregated results
 */
const CACHE = new WeakMap<object, Map<string, AggregatedGroupResponse>>();

export const getAggregatedGroupResponseData = (
  data: V1GroupResponse['groups'],
  options: { primaryKey: string }
): AggregatedGroupResponse => {
  // Attempt to load a previously aggregated result from the cache
  if (data && CACHE.has(data)) {
    const cached = CACHE.get(data)!;
    if (cached.has(options.primaryKey)) {
      return cached.get(options.primaryKey) as AggregatedGroupResponse;
    }
  }

  const aggregation: AggregatedGroupResponse = {};
  if (!data) return aggregation;

  for (const [aggregationKey, group] of Object.entries(data)) {
    // Skip invalid parse result
    const result =
      safeParseJSON<{ key: string; value: unknown }[]>(aggregationKey);
    if (!result.ok) continue;

    const primaryKey = result.value.find((kv) => kv.key === options.primaryKey)
      ?.value as string;

    // Skip entries with an empty or "falsy" value for the primary key
    if (!primaryKey) continue;

    const attributes = (aggregation[primaryKey] =
      aggregation[primaryKey] ?? {});
    for (const kv of result.value) {
      if (kv.key === options.primaryKey) {
        continue;
      }

      const count = group.aggregation_count?.count ?? 1;

      // Get the key values for the secondary key
      const keys = Array.isArray(kv.value) ? kv.value : [kv.value];
      for (const k of keys) {
        // Skip entries with an empty or "falsy" value for the secondary key
        if (!k) continue;

        const base = _get(attributes, [kv.key, k], 0);
        _set(attributes, [kv.key, k], base + count);
      }
    }
  }

  // Persist the aggregated result in the cache
  const cached = CACHE.get(data) ?? new Map();
  cached.set(options.primaryKey, aggregation);
  CACHE.set(data, cached);

  return aggregation;
};
