import {
  useGetVersionUpgrade,
  useListAssuredPackageVersions,
} from '@endorlabs/queries';

/**
 * Get information about an individual Remediation ({@see VersionUpgrade})
 *
 * When the remediation is an Endor Magic Patch, return the corresponding
 * {@see AssuredPackageVersion} resource.
 */
export const useRemediationDetailData = ({
  namespace,
  versionUpgradeUuid,
}: {
  namespace: string;
  versionUpgradeUuid: string;
}) => {
  const qGetVersionUpgrade = useGetVersionUpgrade(
    namespace,
    { uuid: versionUpgradeUuid },
    { enabled: !!namespace }
  );

  const isMagicPatchUpgrade =
    qGetVersionUpgrade.data?.spec?.upgrade_info?.is_endor_patch;

  const dependencyPackageVersionName = qGetVersionUpgrade.data?.spec
    ?.upgrade_info?.direct_dependency_package
    ? [
        qGetVersionUpgrade.data.spec.upgrade_info.direct_dependency_package,
        qGetVersionUpgrade.data.spec.upgrade_info.to_version,
      ].join('@')
    : undefined;

  const qListAssuredPackageVersions = useListAssuredPackageVersions(
    namespace,
    {
      filter: `meta.name=="${dependencyPackageVersionName}"`,
      page_size: 1,
    },
    { enabled: !!isMagicPatchUpgrade && !!dependencyPackageVersionName }
  );

  const versionUpgrade = qGetVersionUpgrade.data;
  const assuredPackageVersion = qListAssuredPackageVersions.data?.objects[0];

  const isLoading = [qGetVersionUpgrade, qListAssuredPackageVersions].some(
    (q) => q.isLoading
  );

  return {
    assuredPackageVersion,
    dependencyPackageVersionName,
    isLoading,
    isMagicPatchUpgrade,
    versionUpgrade,
  };
};
